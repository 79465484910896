import React from 'react';
import './Header.css';
import logo from '../../assets/logo_newfolder.png'; // Adjust the path as needed

const Header = () => {
  return (
    <header className="header text-white">
      <div className="container">
        <div className="d-flex justify-content-between align-items-center py-3">
          <div className="logo">
            <a href="/">
              <img src={logo} alt="Logo" className="logo-img" />
            </a>
          </div>
          <div className="social-icons d-flex">
            <a href="https://www.tiktok.com/@newfoldergames" className="text-white mx-2" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-tiktok"></i>
            </a>
            <a href="https://x.com/NewFolderGamesQ" className="text-white mx-2" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-x-twitter"></i>
            </a>
            <a href="https://www.instagram.com/new.folder.games/" className="text-white mx-2" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-instagram"></i>
            </a>
            <a href="https://discord.gg/bnJv9TaAfT" className="text-white mx-2" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-discord"></i>
            </a>
            <a href="https://www.youtube.com/@newfoldergames" className="text-white mx-2" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-youtube"></i>
            </a>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
