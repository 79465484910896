import React from 'react';
// Import Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import './Hero.css';
import pawImage from '../../assets/cat_paw.png';
import steamImage from '../../assets/steam.png';
import metaImage from '../../assets/meta.png';
import appleImage from '../../assets/apple.png';
import playstationImage from '../../assets/playstation.png';

const Hero = () => {
  return (
    <section className="hero d-flex flex-column justify-content-center align-items-center">
      <div className="hero-content text-center">
        <h1 className="mb-3">Available Now</h1>
        <img src={pawImage} alt="Paw Print" className="paw-image mb-3" />
        <div className="hero-buttons-container d-flex justify-content-center align-items-center flex-wrap">
          <a href="https://store.steampowered.com/app/3016840/I_Am_Cat/?utm_source=iamcatsteam" target="_blank" rel="noopener noreferrer" className="hero-buttons m-2">
            <img src={steamImage} alt="Steam" />
          </a>
          <a href="https://www.meta.com/en-gb/experiences/6061406827268889/?utm_source=iamcatmeta" target="_blank" rel="noopener noreferrer" className="hero-buttons m-2">
            <img src={metaImage} alt="Meta" />
          </a>
          <a href="https://apps.apple.com/us/app/i-am-cat-cat-life-simulator/id6503200399/?utm_source=iamcatapple" target="_blank" rel="noopener noreferrer" className="hero-buttons m-2">
            <img src={appleImage} alt="Apple" />
          </a>
          <a href="https://store.playstation.com/en-us/concept/10012021/?utm_source=iamcatapple" target="_blank" rel="noopener noreferrer" className="hero-buttons m-2">
            <img src={playstationImage} alt="PlayStation" />
          </a>
        </div>
      </div>
    </section>
  );
};

export default Hero;
