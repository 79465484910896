import React from 'react';
import './VideoDescription.css';

const VideoDescription = () => {
  return (
    <div className="video-description text-center text-white">
      <div className="video-container embed-responsive embed-responsive-16by9">
        <iframe 
          className="embed-responsive-item"
          src="https://www.youtube.com/embed/AXRJzYTG3xQ" 
          allowFullScreen
          title="YouTube Video"
        ></iframe>
      </div>
      <p className="video-text mt-4">
        <span className="bold-text">I Am Cat</span> - a unique virtual reality game, there's nothing like it in the world! It's a sandbox adventure centered around a Cat protagonist bearing. A captivating journey where you embrace the role of a Cat. 
        Immerse yourself in a world brimming with diversity and excitement, awaiting your exploration.
      </p>
    </div>
  );
};

export default VideoDescription;
