// src/components/Screenshots/Screenshots.js
import React from 'react';
import './Screenshots.css';

// Import images
import screenshot1 from 'C:/Users/User/iamcat.com/src/assets/screenshot01.png';
import screenshot2 from 'C:/Users/User/iamcat.com/src/assets/screenshot02.png';
import screenshot3 from 'C:/Users/User/iamcat.com/src/assets/screenshot03.png';
import screenshot4 from 'C:/Users/User/iamcat.com/src/assets/screenshot04.png';
import screenshot5 from 'C:/Users/User/iamcat.com/src/assets/screenshot05.png';
import screenshot6 from 'C:/Users/User/iamcat.com/src/assets/screenshot06.png';

const screenshots = [
  screenshot1,
  screenshot2,
  screenshot3,
  screenshot4,
  screenshot5,
  screenshot6,
];

const Screenshots = () => {
  return (
    <div className="screenshots-container">
      {screenshots.map((src, index) => (
        <div className="screenshot-item" key={index}>
          <img src={src} alt={`Screenshot ${index + 1}`} />
        </div>
      ))}
    </div>
  );
};

export default Screenshots;
