// src/components/BuyItMeow/BuyItMeow.js
import React from 'react';
import './BuyitMeow.css';

// Import images for buttons and cat image

import catImage from '../../assets/cat.png'; // Add your cat image path

const BuyItMeow = () => {
  return (
    <div className="buy-it-meow-section">

      <div className="buy-it-meow-content">
        <img src={catImage} alt="Cat" className="cat-image" />
        <h1 className="buy-it-meow-text">Buy It Meow!</h1>
      </div>
      
      
      
    </div>
    
  );
};

export default BuyItMeow;
