import React from 'react';
import './Footer.css';
import logo from '../../assets/logo_newfolder.png'; // Adjust the path as needed

const Footer = () => {
  return (
    <footer className="footer bg-dark text-white py-4">
      <div className="footer-container container">
        <div className="footer-left">
          <a href="/">
            <img src={logo} alt="Logo" className="footer-logo" />
          </a>
          <div className="footer-social-icons">
            <a href="https://www.tiktok.com/@newfoldergames" className="footer-social-link text-white mx-2" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-tiktok"></i>
            </a>
            <a href="https://x.com/NewFolderGamesQ" className="footer-social-link text-white mx-2" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-x-twitter"></i>
            </a>
            <a href="https://www.instagram.com/new.folder.games/" className="footer-social-link text-white mx-2" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-instagram"></i>
            </a>
            <a href="https://discord.gg/bnJv9TaAfT" className="footer-social-link text-white mx-2" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-discord"></i>
            </a>
            <a href="https://www.youtube.com/@newfoldergames" className="footer-social-link text-white mx-2" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-youtube"></i>
            </a>
          </div>
          <p className="footer-copyright">
            &copy; 2024 LTD "NEW FOLDER GAMES".
          </p>
        </div>
        <div className="footer-right">
          <nav className="footer-nav">
            <a href="https://newfolderstudio.com/" className="footer-nav-link text-white mx-2" target="_blank" rel="noopener noreferrer">About Us</a>
            <a href="https://newfolderstudio.com/games" className="footer-nav-link text-white mx-2" target="_blank" rel="noopener noreferrer">Games</a>
            <a href="https://newfolderstudio.com/privacy-policy" className="footer-nav-link text-white mx-2" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
          </nav>
          <span className="footer-contact text-white">Contact: media@newfolder.studio</span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
