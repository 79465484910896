// src/App.js
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Header from './components/Header/Header';
import Hero from './components/Hero/Hero';
import VideoDescription from './components/VideoDescription/VideoDescription';
import Screenshots from './components/Screenshots/Screenshots';
import Ratings from './components/Ratings/Ratings';
import BuyItMeow from './components/BuyItMeow/BuyitMeow';
import Footer from './components/Footer/Footer';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { initGA, logPageView } from './analytics';

function App() {
  useEffect(() => {
    initGA();
  }, []);

  const location = useLocation();

  useEffect(() => {
    logPageView();
  }, [location]);

  return (
    <div className="App d-flex flex-column min-vh-100">
      <Header />
      <Hero />
      <main className="container mt-4">
        <VideoDescription />
        <Screenshots />
        <Ratings />
        <BuyItMeow />
      </main>
      <Footer />
    </div>
  );
}

export default App;
